// 申遗承诺
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'XSYJCGMC',
                    label: '学术研究成果名称',
                    minWidth: 135
                }, {
                    prop: 'YJFX_DESC',
                    label: '研究方向',
                    minWidth: 128,
                }, {
                    prop: 'XSYJCGLB_DESC',
                    label: '学术研究成果类别',
                    minWidth: 128,
                }, {
                    prop: 'LJ',
                    label: '文件',
                    minWidth: 90,
                    showFileBtn: true,
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;